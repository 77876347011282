import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  getAction,
  getInstanceName,
  isClient,
  isUserLogged,
  SIGNING_AUDIT_TRAIL_TYPE,
} from '../helpers/utils';
import {
  addToCart,
  handleCheckAgreement,
  setAuditTrailStatus,
  setAuditTrialData,
  setLoader,
} from '../store/actions';
import {
  selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  selectBillingAddresses,
  selectCart,
  selectCoinPaymentDiscount,
  selectConditionToShowPurchaseOrSignButton,
  selectIbpProduct,
  selectIfIbpInTheCart,
  selectIfUserNeedToSignAgreement,
  selectPaymentCardForCvvConfirm,
  selectPaymentsWithDefault,
  selectPreAuthorizeModalCondition,
  selectShowVatAmount,
  showAutoRenewalMessage,
} from '../store/selectors';
import {
  selectAuditTrial,
  selectCalculatedPrice,
  selectElectronicSignments,
  selectEntityUserDetails,
  selectEntityUserSubscriptions,
  selectProductsWithSubscriptions,
} from '../store/selectors/entities';
import { selectChosenPaymentMethod, selectModal } from '../store/selectors/global';
import { createNavigateTo, externalLinks, pageLinks } from '../helpers/navigation';
import SignPdfModal from '../components/sign-pdf-modal';
import showPdfAgreement from '../components/checkout-total/showPdfAgreement';
import Button from '../components/button';
import styles from '../components/checkout-total/checkout-total.module.scss';
import getUserElectronicSign from '../services/api/actions/getUserElectronicSign';
import Loader from '../components/loader';
import setNotification from '../helpers/notifications';

const stateSelector = createStructuredSelector({
  billingAddresses: selectBillingAddresses,
  userDetails: selectEntityUserDetails,
  electronicSignments: selectElectronicSignments,
  calculatedPrice: selectCalculatedPrice,
  auditTrial: selectAuditTrial,
  cartData: selectCart,
  ibiProduct: selectIbpProduct,
  isIbpInTheCart: selectIfIbpInTheCart,
  subscriptions: selectEntityUserSubscriptions,
});

const Agreement = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showSignPdf, toggleSignPdf] = useState(false);
  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.checkout' });
  const [customLoader, setCustomLoader] = useState(false);

  const {
    billingAddresses,
    userDetails,
    electronicSignments,
    calculatedPrice,
    auditTrial,
    ibiProduct,
    subscriptions,
    cartData,
  } = useSelector(stateSelector);

  const cart = cartData?.products?.length ? cartData : { products: [ibiProduct] };

  const isRenew = ibiProduct?.purchased || ibiProduct?.isRenew;

  const changeAuditTrailStatus = (step, externalId) => {
    dispatch(setAuditTrailStatus({ step, externalId }));
  };

  useEffect(() => {
    dispatch(setAuditTrialData({ id: '', step: 0 }));

    if (isClient) {
      try {
        localStorage.removeItem('audit-trial-id');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('CHECKOUT setAuditTrialData', err);
      }
    }
  }, []);

  const getAgreements = () => {
    if (isClient) {
      // dispatch(handleCheckAgreement());
      const ibiItem = {
        planId: ibiProduct.id,
        action: getAction(isRenew),
      };
      dispatch(
        getUserElectronicSign
          .withQuery('?type=signature_ibi')
          .action({ items: [ibiItem], totalAmountForInitialItems: ibiProduct?.purchased ? 0 : 1 })
      );
    }
    setCustomLoader(false);
  };

  useEffect(() => {
    if (isClient && userDetails && (subscriptions || isRenew) && !electronicSignments) {
      getAgreements();
    }
  }, [userDetails, isRenew, subscriptions]);

  const showPdf = (action) => {
    if (!isClient) {
      return;
    }
    try {
      // eslint-disable-next-line consistent-return
      return showPdfAgreement(action, userDetails);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while showing PDF Document');
    }
  };

  const onSubmit = () => {
    // setCustomLoader(true);
    // console.log('ON SUBMIT');
    // getAgreements();
    // toggleSignPdf(false);

    dispatch(setLoader(false));

    setNotification('success', {
      message: 'Thank You for signing the new publisher agreement',
      title: '',
    });

    setTimeout(() => {
      createNavigateTo(pageLinks.home)();
    }, 2000);
  };

  useEffect(() => {
    if (!customLoader && !showSignPdf && electronicSignments) {
      changeAuditTrailStatus(SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step);
      toggleSignPdf(true);
    }
  }, [showSignPdf, toggleSignPdf, electronicSignments]);

  if (!isUserLogged() || customLoader || !electronicSignments) {
    return <Loader isLoading />;
  }

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout pageWrapperClassName="page_wraper">
        {/*{!!electronicSignments?.agreements?.length && (*/}
        {/*  <div className={styles.wrapper}>*/}
        {/*    <span>Agreements:</span>*/}
        {/*    {electronicSignments?.agreements.map((agreement) => (*/}
        {/*      <div className={styles.electronicSignListContainer}>*/}
        {/*        <div*/}
        {/*          className={styles.agreementLink}*/}
        {/*          role="button"*/}
        {/*          tabIndex={0}*/}
        {/*          onKeyDown={() => window.open(agreement.url, '_blank')?.focus()}*/}
        {/*          onClick={() => window.open(agreement.url, '_blank')?.focus()}*/}
        {/*        >*/}
        {/*          <span>ID: {agreement.signingExternalId}</span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*<div className={styles.electronicSignListContainer}>*/}
        {/*  <Button*/}
        {/*    size="medium"*/}
        {/*    className={styles.submitButton}*/}
        {/*    onClick={moveToSignUp2}*/}
        {/*    loaderType={null}*/}
        {/*  >*/}
        {/*    Continue*/}
        {/*  </Button>*/}
        {/*</div>*/}

        <SignPdfModal
          isModal
          show={showSignPdf}
          toggle={toggleSignPdf}
          onSubmit={onSubmit}
          userDetails={userDetails}
          cartData={cart}
          electronicSignments={electronicSignments}
          showPdf={() => showPdf('getBlob')}
          billingAddresses={billingAddresses}
          setAuditTrialStatus={changeAuditTrailStatus}
          externalId={auditTrial.id}
          calculatedPrice={calculatedPrice}
          payBtnText="Sign"
          signatureType="signature_ibi_italy"
        />
      </Layout>
    </>
  );
};

export default Agreement;
